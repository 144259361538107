<template>
  <v-container fluid>
    <base-material-card
      icon="fa fa-truck"
      title="Reporte de Horas de Visitas"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_desde" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_desde" label="Fecha inicial" readonly v-on="on"
                            :error-messages="errors.fecha_desde"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_desde" @input="menu_fecha_desde = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu v-model="menu_fecha_hasta" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="form.fecha_hasta" label="Fecha final" readonly v-on="on"
                            :error-messages="errors.fecha_hasta"></v-text-field>
            </template>
            <v-date-picker v-model="form.fecha_hasta" @input="menu_fecha_hasta = false; load()"
                           locale="es-MX"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/vendedores/all"
            text="razon_social"
            label="Vendedor"
            @change="
							(value) => {
								form.vendedor_id = value;
								load();
							}
						"
            :valor="form.vendedor_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/rutas"
            text="nombre"
            label="Ruta"
            @change="(value) => {form.ruta_id = value; load()}"
            :paginate="false"
            :valor="form.ruta_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="8">
          <v-autocomplete
            v-model="form.dias"
            chips
            deletable-chips
            multiple
            label="Dias de Visitas"
            item-text="nombre"
            item-value="id"
            :items="dias"
            :error-messages="errors.dias"
            @change="load()"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" v-if="false">
          <v-btn color="primary" @click="downloadPdf">Descargar PDF</v-btn>
          <v-btn color="green" @click="downloadExcel">Descargar Excel</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="rutas"
            :loading="loading"
            hide-default-footer
            disable-form
          >
            <template v-slot:item.inicio_venta="{ item }">
              {{showHoras(item.inicio_venta)}}
            </template>
            <template v-slot:item.fin_venta="{ item }">
              {{showHoras(item.fin_venta)}}
            </template>
            <template v-slot:item.minutos="{ item }">
              {{showMinutos(item)}}
            </template>
            <template v-slot:body.append>
              <tr>
                <td class="font-weight-bold"></td>
                <td class="font-weight-bold"></td>
                <td colspan="2"></td>
                <td ></td>
                <td class="font-weight-bold">

                </td>
                <td colspan="2" class="text-right font-weight-bold">
                  Minutos: {{totalMinutes}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination v-model="form.page" class="my-4"
                        :length="form.last_page"
                        @input="load" :total-visible="10"
                        color="primary"></v-pagination>
        </v-col>
        <v-col col="12" v-if="false">
          <v-form
            v-model="form.model"
            class="my-4"
            :length="form.last_page"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-form>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>

import moment from 'moment';
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";


export default {
  name:'ReporteHorasVisitas',
  components: {
    CustomSelect,
  },
  data: () => ({
    errors: {},
    headers: [
      {text: "Fecha", value: "fecha", sortable: false},
      {text: "Ruta", value: "ruta.nombre", sortable: false},
      {text: "Vendedor", value: "vendedor.razon_social", sortable: false},
      {text: "Cliente", value: "sucursal.cliente.razon_social", sortable: false},
      {text: "Sucursal", value: "sucursal.nombre", sortable: false},
      {text: "Hora Inicio", value: "inicio_venta", sortable: false},
      {text: "Hora Fin", value: "fin_venta", sortable: false},
      {text: "Minutos", value: "minutos", sortable: false},
    ],
    form: {
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: moment().format('YYYY-MM-DD'),
      vendedor_id: "",
      ruta_id: "",
      dias: [],
      last_page: 1,
      page: 1,
      model:true
    },
    dias: [],
    menu_fecha_desde: false,
    menu_fecha_hasta: false,
    loading: false,
    rutas: [],
    total: 0,
    totales: {
      impuestos: [],
    },
    items:[]
  }),
  mounted() {
    this.dias = [
      {
        id: 1, nombre: 'Domingo'
      },
      {
        id: 2, nombre: 'Lunes'
      },
      {
        id: 3, nombre: 'Martes'
      },
      {
        id: 4, nombre: 'Miercoles'
      },
      {
        id: 5, nombre: 'Jueves'
      },
      {
        id: 6, nombre: 'Viernes'
      },
      {
        id: 7, nombre: 'Sabado'
      }
    ];
    this.load();
  },
  computed: {
    totalMinutes() {
      if(this.items && this.items.length){
        return this.items.reduce((total, item)=>total + item.minutes,0)
      }
      return 0;
    }
  },
  methods: {
    showMinutos(item){
      if(item.inicio_venta && item.fin_venta){
        const inicio = moment(item.inicio_venta);
        const fin = moment(item.fin_venta);
        const duration = moment.duration(fin.diff(inicio));
        return parseInt(duration.asMinutes());
      }
      return 0;
    },
    showHoras(fecha){
      return fecha ? fecha.substring(11,fecha.length):'';
    },
    calcularEfectividad(item) {
      const realizados = item.viajes - item.no_visitas;
      return parseFloat((realizados / item.sucursales) * 100).toFixed(2);
    },
    calcularSubtotal(item) {
      return item.producto.precio_default.precio * item.cantidad;
    },
    calcularImpuestos(item) {
      const subtotal = this.calcularSubtotal(item);
      return item.producto.impuestos.reduce(
        (total, impuesto) => total + subtotal * (impuesto.porcentaje / 100),
        0
      );
    },
    reset() {
      this.form.page = 1;
      this.load();
    },
    load() {
      this.loading = true;
      this.rutas = [];
      this.$http
        .get("/reportes/rutas/horas_visitas", {
          params: this.form,
        })
        .then((response) => {
          this.rutas = response.data.data;
          this.items = response.data.rows;
          this.total = response.data.cantidad_sum;
          this.totales = response.data.totales;
          this.form.last_page = response.data.last_page;
          this.form.page = response.data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadPdf() {
      this.$http({
        url: `/almacenes/vendedores/pdf`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteAlmacenesVendedores.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    downloadExcel(item) {
      this.$http({
        url: `/almacenes/vendedores/excel`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteAlmacenesVendedores.xlsx`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    retryDownloadPdf(item) {
      this.$http
        .patch(`/facturas/${item.id}/retry-download`)
        .then((response) => {
          this.$store.commit("SET_SNACKBAR", {
            text: "Solicitud de generación de PDF exitosa; intente descarga más tarde",
            color: "success",
          });
        });
    },
  },
};
</script>
