<template>
  <div>
    <v-autocomplete v-if="paginate"
                    :value="valor"
                    :items="items"
                    :search-input.sync="search"
                    :placeholder="placeholder"
                    hide-no-data
                    :item-text="text"
                    :label="label"
                    :error-messages="errorMessages"
                    return-object
                    @change="changeAutocomplete"
                    :disabled="disabled"
    >
    </v-autocomplete>
    <v-select
      :disabled="disabled"
      v-else
      :value="valor"
      :items="items"
      :label="label"
      :item-value="id"
      :item-text="text"
      :error-messages="errorMessages"
      @change="changeSelect"
      :class="required?'required':''"
      :multiple="multiple"
      :chips="multiple"
      deletable-chips
    ></v-select>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: "custom-select",
  props: {
    url: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'id'
    },
    text: {
      type: [String, Function],
      default: 'nombre'
    },
    errors: {
      type: String
    },
    paginate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    valor: {
      type: [String, Object, Number, Array],
      default: () => {
      },
    },
    params: {
      type: Object,
      default: () => {
      },
    },
    firstSelect: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    firstText: {
      type: String,
      default: 'Seleccione una opcion'
    },
    errorMessages: {
      type: [String, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      items: []
    }
  },
  mounted() {
    if (!this.paginate) {
      this.loadData();
    }
  },
  watch: {
    search(val, old) {
      if (val) {
        this.loadDataDebounce();
      } else {
        this.$emit('change', '')
      }
    },
    params: {
      handler: function (after, before) {
        if (after) {
          //this.loadDataDebounce();
          this.sendData();
        } else {
          this.$emit('change', '')
          this.items = [];
        }
      },
      deep: true
    },
  },
  methods: {
    changeAutocomplete(value){
      this.$emit('change', value);
    },
    changeSelect(value){
      this.$emit('change', value);
      const elements = JSON.parse(JSON.stringify(this.items));
      const item = elements.find((ele)=> ele.id == value);
      if(item){
        this.$emit('selected', item);
      }
    },
    loadDataDebounce: _.debounce(function () {
      this.sendData();
    }, 500),
    loadData() {
      this.sendData();
    },
    sendData() {
      let localParams = {
        search: this.search
      };
      this.$http.get(this.url, {
        params: {...localParams, ...this.params}
      }).then(response => {
        this.processResponse(response);
      });
    }
    ,
    processResponse(response) {
      let first = {id: ''};
      let data = (this.paginate && response.data.data) ? response.data.data : response.data;
      // console.log(this.label, '<- dada.', this.paginate && response.data.data);
      first[this.text] = this.firstText;
      if (this.firstSelect) {
        data.unshift(first)
      }
      if(this.paginate){
        if(data.length){
          this.items = data;
        }
      }else{
        this.items = data;
      }
    }
  }
}
</script>
